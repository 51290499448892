'use client'
import { useEffect, useState } from 'react'

import { useInputField } from '@/components/atoms/Form/useInputField'
import { InputHelperText, InputHelperTextProps } from './InputHelperText'

import styles from './Input.module.css'

const useInputElementValidationMessage = () => {
  const { id } = useInputField()
  const [inputElementValidationMessage, setInputElementValidationMessage] =
    useState('')

  useEffect(() => {
    const inputElement = document.getElementById(id) as HTMLInputElement | null

    if (!inputElement) {
      return
    }

    inputElement.setAttribute('aria-describedby', `error-text-${id}`)

    const inputField = inputElement.closest(`.${styles.field}`)

    inputElement.addEventListener('blur', (e) => {
      e.preventDefault()
      setInputElementValidationMessage(inputElement.validationMessage)

      if (!inputField) {
        return
      }

      const isValid = inputElement.checkValidity()

      if (isValid) {
        return
      }

      inputElement.setAttribute('aria-invalid', 'true')

      inputField.setAttribute('data-status', 'error')
    })

    inputElement.addEventListener('input', () => {
      const isValid = inputElement.checkValidity()

      if (!inputField || !isValid) {
        return
      }

      setInputElementValidationMessage('')
      inputField.setAttribute('data-status', 'success')
      inputElement.setAttribute('aria-invalid', 'false')
    })

    return () => {
      inputElement.removeEventListener('invalid', () => {
        setInputElementValidationMessage(inputElement.validationMessage)
      })
    }
  }, [id, inputElementValidationMessage])

  return inputElementValidationMessage
}

const InputErrorText = ({
  children,
  ...props
}: Partial<InputHelperTextProps>) => {
  const { id } = useInputField()

  const validationMessage = useInputElementValidationMessage()

  if (!validationMessage) {
    return null
  }

  return (
    <InputHelperText
      className={styles.error}
      id={`error-text-${id}`}
      {...props}
    >
      {children ?? validationMessage}
    </InputHelperText>
  )
}

export default InputErrorText
