'use client'
import { ReactNode } from 'react'

import { useInputField } from '@/components/atoms/Form/useInputField'
import Typography, {
  TypographyProps,
} from '@/components/atoms/Typography/Typography'

import styles from './Input.module.css'

export type InputHelperTextProps = TypographyProps & {
  children: ReactNode
}

export const InputHelperText = ({
  children,
  ...props
}: InputHelperTextProps) => {
  const { id } = useInputField()

  return (
    <Typography
      id={`helper-text-${id}`}
      variant="text"
      size="xs"
      weight="semibold"
      className={styles.helperText}
      {...props}
    >
      {children}
    </Typography>
  )
}

export default InputHelperText
