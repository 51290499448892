'use client'
import { isEmpty } from 'lodash'
import { PrefetchKind } from 'next/dist/client/components/router-reducer/router-reducer-types'
import { useRouter } from 'next/navigation'
import {
  DetailedHTMLProps,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ServerActionError } from '@/lib/actions/Error'
import { ServerActionOptions } from '@/lib/actions/ServerActionOptions'
import { ServerActionResult } from '@/lib/actions/ServerActionResult'

export type FormAction = (
  formData: FormData,
  options?: ServerActionOptions
) => Promise<ServerActionResult>

export type FormProps = Omit<
  DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  'action'
> & {
  // TODO: Get rid of this as this is a temporary solution for `redirect` from `next/navigation` not working with Parallel Routes
  redirectTo?: string
  action?: FormAction
  fallback?: ReactNode
}

type ClientFormContextResult = {
  error: ServerActionError | null
  clearErrors: () => void
}

export const FormContext = createContext<ClientFormContextResult>(
  {} as ClientFormContextResult
)

export const useFormContext = () => {
  const context = useContext(FormContext)

  if (!context || isEmpty(context)) {
    throw new Error('useFormContext must be used within a FormContextProvider')
  }

  return context
}

const Form = ({
  children,
  action,
  redirectTo,
  fallback,
  ...props
}: FormProps) => {
  const [error, setError] = useState<null | ServerActionError>(null)

  const clearErrors = useCallback(() => {
    setError(null)
  }, [])

  const router = useRouter()

  /**
   * This is temporary until Next fixes `redirect` from `next/navigation`
   */
  const skipServerSideRedirect = useRef(false)

  useEffect(() => {
    if (redirectTo) {
      skipServerSideRedirect.current = true
    }
  }, [redirectTo])
  /* */

  return (
    <FormContext.Provider value={{ error, clearErrors }}>
      <form
        {...props}
        action={async (formData) => {
          if (typeof action !== 'function') {
            return
          }

          /**
           * Start prefetching the page that we will redirect to
           */
          if (redirectTo) {
            router.prefetch(redirectTo, {
              kind: PrefetchKind.FULL,
            })
          }

          const result = await action(formData, {
            redirectTo,
            skipServerSideRedirect: skipServerSideRedirect.current,
          })

          /**
           * Handle errors
           */

          if (result?.type === 'error' && result?.error) {
            setError(result.error)

            return
          }

          if (result?.type === 'ok' && result?.redirectTo) {
            router.push(result?.redirectTo)
          }

          /**
           * This is temporary until Next fixes `redirect` from `next/navigation`
           * TODO: Get rid of this once is fixed
           */
          if (redirectTo && skipServerSideRedirect.current) {
            router.push(redirectTo)
          }
        }}
      >
        {children}
      </form>
    </FormContext.Provider>
  )
}

export default Form
