'use client'
import { useFormStatus } from 'react-dom'

import Button, { ButtonProps } from './Button'

type SubmitButtonProps = ButtonProps & {
  type?: 'submit'
}

const SubmitButton = ({ children, onClick, ...props }: SubmitButtonProps) => {
  const { pending } = useFormStatus()

  return (
    <Button {...props} type="submit" aria-label="Submit" isLoading={pending}>
      {children}
    </Button>
  )
}

export default SubmitButton
