'use client'
import classNames from 'classnames'
import { createContext, useId } from 'react'

import Box, { BoxProps } from '@/components/atoms/Box/Box'

import styles from './Input.module.css'

export type InputFieldContextResult = {
  id: string
  isDisabled?: boolean
  status?: 'success' | 'error'
}

// This could be a createServerContext() but that was causing errors in the current version of React
export const InputFieldContext = createContext<InputFieldContextResult>({
  id: '',
  isDisabled: false,
})

type InputFieldProps = Partial<InputFieldContextResult> & BoxProps<'div'>

const InputField = ({
  isDisabled,
  status,
  children,
  className,
  ...props
}: InputFieldProps) => {
  const id = useId()

  return (
    <InputFieldContext.Provider
      value={{
        id,
        isDisabled,
        status,
      }}
    >
      <Box className={classNames(className, styles.field)} {...props}>
        {children}
      </Box>
    </InputFieldContext.Provider>
  )
}

export default InputField
