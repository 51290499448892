'use client'
import classNames from 'classnames'

import { BoxProps } from '@/components/atoms/Box/Box'
import { useFormStatus } from 'react-dom'
import ErrorIcon from '@/components/icons/ErrorIcon'
import { useFormContext } from '@/components/atoms/Form/Form'

import styles from './FormErrorBanner.module.css'

type ErrorMessageProps = BoxProps & {
  message?: string
}

export const ErrorBanner = ({
  message = 'An unexpected error occurred',
  className,
  ...props
}: ErrorMessageProps) => (
  <div className={classNames(styles.banner, className)}>
    <div {...props} className={styles.error} role="alert">
      <ErrorIcon />

      <span>{message}</span>
    </div>
  </div>
)

type FormErrorBannerProps = BoxProps

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use `FormContextErrorBanner` or `ErrorBanner` instead.
 */
const FormErrorBanner = ({ className, ...props }: FormErrorBannerProps) => {
  const status = useFormStatus()

  // @ts-expect-error TODO: fix this API change
  const error = status?.error

  if (!error) {
    return null
  }

  return <ErrorBanner {...props} message={error?.message} />
}

export const FormContextErrorBanner = ({
  className,
  ...props
}: FormErrorBannerProps) => {
  const { error } = useFormContext()

  if (!error) {
    return null
  }

  return <ErrorBanner message={error?.message} {...props} />
}

export default FormErrorBanner
