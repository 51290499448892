'use client'
import classNames from 'classnames'
import { ReactNode, forwardRef } from 'react'

import { BoxProps } from '@/components/atoms/Box/Box'
import { useInputField } from '@/components/atoms/Form/useInputField'

import styles from './Input.module.css'

export type InputProps = Omit<BoxProps<'input'>, 'value' | 'id'> & {
  icon?: ReactNode
  variant?: 'outlined' | 'ghost'
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { icon, className, variant = 'outlined', hidden, as, ...props }: InputProps,
    ref
  ) => {
    const { id } = useInputField()

    const Element = as ?? 'input'

    if (hidden) {
      return (
        <Element
          ref={ref}
          {...props}
          id={id}
          aria-describedby={`helper-text-${id}`}
          hidden
        />
      )
    }

    return (
      <div
        className={classNames(styles.input, className)}
        data-variant={variant}
      >
        {!!icon && icon}
        <Element
          ref={ref}
          {...props}
          id={id}
          aria-describedby={`helper-text-${id}`}
        />
      </div>
    )
  }
)

Input.displayName = 'Input'

export default Input
