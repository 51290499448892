'use client'
import { BoxProps } from '@/components/atoms/Box/Box'
import { useInputField } from '@/components/atoms/Form/useInputField'
import classNames from 'classnames'

import styles from './Input.module.css'

const variants = {
  default: styles.label,
  unstyled: '',
}

export type LabelProps = BoxProps<'label'> & {
  variant?: keyof typeof variants
}

export const Label = ({
  children,
  className,
  variant = 'default',
  ...props
}: LabelProps) => {
  const { id } = useInputField()

  return (
    <label
      htmlFor={id}
      className={classNames(className, variants[variant])}
      {...props}
    >
      {children}
    </label>
  )
}

export default Label
