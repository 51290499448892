import classNames from 'classnames'

import Box, { BoxProps } from '@/components/atoms/Box/Box'

import styles from './Typography.module.css'

const TypographySizes = {
  xxl: styles.xxl,
  xl: styles.xl,
  lg: styles.lg,
  md: styles.md,
  sm: styles.sm,
  xs: styles.xs,
}

type TypographySize = keyof typeof TypographySizes

const TypographyWeights = {
  light: styles.light,
  regular: styles.regular,
  medium: styles.medium,
  semibold: styles.semibold,
}

type TypographyWeight = keyof typeof TypographyWeights

const TypographyVariants = {
  text: styles.text,
  display: styles.display,
}

type TypographyVariant = keyof typeof TypographyVariants

type PossibleTextTags =
  | 'p'
  | 'span'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'label'

export type TypographyProps = BoxProps<PossibleTextTags> & {
  size?: TypographySize
  weight?: TypographyWeight
  variant?: TypographyVariant
}

export const Typography = ({
  children,
  size = 'md',
  weight = 'regular',
  variant = 'text',
  color,
  className,
  style,
  as = 'p',
  ...props
}: TypographyProps) => {
  return (
    <Box
      as={as}
      className={classNames(
        TypographySizes[size],
        TypographyWeights[weight],
        TypographyVariants[variant],
        className
      )}
      style={{
        ...style,
        color,
      }}
      {...props}
    >
      {children}
    </Box>
  )
}

export default Typography
